import Repository from "./repository"
export default {
    mapperDataInfo(data) {
        const tanggalKunjungan  = data.tanggalKunjungan
         const dayNum = new Date(tanggalKunjungan).getDay()
         const hari = Repository.changeDay(dayNum)
         const jamMulai = data.jamMulai ? data.jamMulai.slice(0, 5) : '-'
         const jamSelesai = data.jamSelesai ? data.jamSelesai.slice(0, 5) : '-'
         const tanggal = Repository.formatDate(tanggalKunjungan)
         return {
            patientName: data.namaPasien,
            dokterName: data.dokter ? data.dokter.nama : '-',
            spesialis: data.dokter && data.dokter.spesialisasi ?data.dokter.spesialisasi : '-',
            hari,
            tanggalKunjungan: tanggal,
            jamMulai,
            jamSelesai
        }
    },
    addToken (data, item) {
        return {
            ...data,
            accessToken: item.token,
            sessionId: item.sessionId
        }
    }
}