import axios from 'axios'
const INFO_RESERVASIS = 'msappointment/api/v3/reservasi-backoffice'
const INFO_GENERATED = 'mstelemedicine/api/v1/getByGenId'
const INFO_CONTACT_CENTER = 'msappointment/api/contact-center'
const TELECONSULT_CONFIG = 'msappointment/api/teleconsult-config'
export default {
  getInfoData (generatedId) {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/${INFO_RESERVASIS}/${generatedId}`)
        .then(_respData => {
          if (_respData.data.status == 'CANCEL') {
            reject(new Error())
          } else {
            resolve(_respData.data)
          }
        })
        .catch(err => reject(new Error(err)))
    })
  },
  getTokenAndSesion (data) {
    return new Promise((resolve, reject) => {
      const generatedId = data.gid
      const params = new URLSearchParams()
      params.append('key', data.key)
      if(data.did) params.append('did', data.did)
      else if(data.tl) params.append('tl', data.tl.slice(0, 10))
      axios.get(`${process.env.VUE_APP_BASE_URL}/${INFO_GENERATED}/${generatedId}`,
      { params }
      ).then(_resp => resolve(_resp))
      .catch((_err) => {
        reject(_err.response)
      })

    })
  },
    getNumberContact (tenantId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/${INFO_CONTACT_CENTER}`, {
          headers: {
            "X-TenantId": tenantId,
          },
        }).then(_resp => {
          resolve(_resp.data)
        }).catch(err => reject(new Error(err)))
      })
    },
    getConfigMessage(tenantId) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_URL}/${TELECONSULT_CONFIG}`, {
          headers: {
            "X-TenantId": tenantId,
          },
        }).then(_resp => {
          resolve(_resp.data)
        }).catch(err => reject(new Error(err)))
      })
    },
    changeDay(day) {
      if (day === 'MONDAY' || day === 1) {
        return 'Senin'
      } else if (day === 'TUESDAY' || day === 2) {
        return 'Selasa'
      } else if (day === 'WEDNESDAY' || day === 3) {
        return 'Rabu'
      } else if (day === 'THURSDAY' || day === 4) {
        return 'Kamis'
      } else if (day === 'FRIDAY' || day === 5) {
        return 'Jumat'
      } else if (day === 'SATURDAY' || day === 6) {
        return 'Sabtu'
      } else if (day === 'SUNDAY' || day === 0) {
        return 'Minggu'
      }
    },
    formatDate (date) {
      let data = date.split('-')
      if (data.length < 2) return ''
      const day = data[2]
      const month = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
      const year = data[0]
      return `${day} ${month[data[1] - 1]} ${year}`
    }
}