import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAccess: false,
    accessToken: null,
    sessionId: null,
    guestAccessToken: null,
    telekonsulData: {},
    roleDokter: false,
    userData: {},
    linkPasien: '',
    dataTelekonsul: {},
    allDataTelekonsul: []
  },
  mutations: {
    SET_DATA_TELEKONSUL(state, res) {
      state.allDataTelekonsul = res
    },
    SET_CAN_ACCESS(state, res) {
      state.isAccess = res
    },
    SET_ACCESS_TOKEN(state, payload) {
      state.accessToken = payload
    },
    SET_LINK_PASIEN(state, payload) {
      state.linkPasien = payload
    },
    SET_SESSION_ID(state, payload) {
      state.sessionId = payload
    },
    SET_GUEST_TOKEN(state, payload) {
      state.guestAccessToken = payload
    },
    SET_TELE_DATA(state, payload) {
      // state.telekonsulData = payload.reservasi.jadwal
      state.accessToken = payload.accessToken
      state.sessionId = payload.sessionId
      // state.guestAccessToken = payload.guestAccessToken
      state.dataTelekonsul = payload
      if (state.roleDokter) {
        state.userData = {
          name: `DOKTER - ${payload.dokterName}`
        }
      } else {
        state.userData = {
          name: `PATIENT - ${payload.patientName}`
        }
      }
    },
    SET_ROLE_DOKTER(state, payload) {
      state.roleDokter = payload
    },
    SET_USER_DATA(state, payload) {
      state.userData = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
