<template>
  <div id="app" >
    <router-view />
    <span class="version-commit"> {{versionCommit}}</span>
  </div>
</template>
<script>
export default {
  data() {
        return {
            versionCommit: process.env.VUE_APP_COMMIT_ID || 0
        }
    },
  mounted () {
    var ua = navigator.userAgent;
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    var x = document.getElementsByTagName("BODY")[0];
    if (isSafari) {
      x.classList.add("b-mobile");
      if (/iPhone|iPod/i.test(ua)) {
        x.classList.add("iphone");
      }
    } else if (
      /Android|webOS|Tablet|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(
        ua
      )
    ) {
      x.classList.add("b-mobile");
    } else /Chrome/i.test(ua);
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
   window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }
}
</script>
<style>
 .version-commit{
        position: fixed;
        bottom: 0;
        left: 0;
        font-size: 11px;
        font-weight: 600;
    }
#devices-list2 {
  position: absolute;
  background-color: #444244;
  min-width: 80px;
  bottom: 43px;
  z-index: 20;
  border-radius: 10px;
}
.device-name {
  display: flex;
  justify-content: center;
  color: white;
  background-color: #393839;
  border: solid 1px #767575;
  border-radius: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  width: 296px;
}
#devices-box:first-child {
  margin-top: 20px;
}
#devices-box:last-child {
  margin-bottom: 15px;
}
.device-icon {
  margin-right: 10px;
}
</style>
